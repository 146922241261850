<!--统计报表-监测点超标统计-->
<template>
  <section>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" :model="filter" size="mini">
        <el-form-item>
          <el-input
            v-model.trim="filter.LocaleId"
            placeholder="监测点编号"
            @keyup.native.enter.stop="sousuo"
          >
            <!-- <el-button
              slot="prepend"
              icon="el-icon-refresh"
              @click="handleRefresh"
            ></el-button> -->
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-cascader
            v-model="filter.owner"
            @change="sousuo"
            placeholder="所属单位"
            :options="customerTree"
            :props="orgProps"
            clearable
            filterable
          />
        </el-form-item>
        <!-- <el-form-item>
          <el-checkbox v-model="filter.LocaleWithDevice" @change="sousuo"
            >历史监测点</el-checkbox
          >
        </el-form-item> -->
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-download"
            @click="handleDownload"
            >导出</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="showId">显示监测点编号</el-checkbox>
        </el-form-item>
        <br />
        <el-form-item v-model="reportType">
          <el-radio-group v-model="reportType">
            <el-radio :label="1">日报表</el-radio>
            <el-radio :label="2">月报表</el-radio>
            <el-radio :label="3">年报表</el-radio>
            <el-radio :label="4">自定义</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-show="reportType === 1">
          <el-date-picker
            v-model="filter.AcquitBeginDate"
            type="date"
            value-format="yyyy-MM-dd"
            @change="sousuo"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item v-show="reportType === 2">
          <el-date-picker
            v-model="filter.AcquitMonth"
            type="month"
            value-format="yyyy-MM"
            @change="sousuo"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item v-show="reportType === 3">
          <el-date-picker
            v-model="filter.AcquitYear"
            type="year"
            value-format="yyyy"
            @change="sousuo"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item v-show="reportType === 4">
          <el-date-picker
            v-model="filter.peorid"
            type="daterange"
            value-format="yyyy-MM-dd"
            @change="sousuo"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 列表 -->
    <el-col :span="24">
      <el-table
        :data="datalist"
        size="mini"
        border
        highlight-current-row
        v-loading="loading"
        :max-height="clientHeight"
        style="width: 100%"
      >
        <el-table-column type="index" label="#" align="center" width="55" />
        <el-table-column
          prop="LocaleName"
          label="监测点名称"
          width="160"
          show-overflow-tooltip
          header-align="center"
        >
          <template slot-scope="{ row }">
            <el-button type="text">{{ row.LocaleName }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="LocaleId"
          v-if="showId"
          label="监测点编号"
          width="160"
          show-overflow-tooltip
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="ExceedStandardTimes"
          label="超标次数"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="AvgEmissionsConc"
          label="油烟平均折算浓度"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="AvgGranuleConc"
          label="颗粒物平均折算浓度"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="AvgHydrocarbonConc"
          label="非甲烷总烃平均折算浓度"
          align="center"
          header-align="center"
        />
      </el-table>
    </el-col>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar">
      <el-pagination
        small
        background
        @size-change="v => handlePageChange(v, 'PerPage')"
        @current-change="v => handlePageChange(v, 'Page')"
        :current-page.sync="filter.Page"
        :page-size="filter.PerPage"
        :page-sizes="[20, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        style="display: inline-block; margin-left: 15px"
      >
      </el-pagination>
    </el-col>
  </section>
</template>
<script>
// DetectorExcess
import { mapState } from "vuex";
import { join, export_json } from "@/util/index";

export default {
  components: {},
  data() {
    return {
      loading: false,
      datalist: [],
      total: 0,
      filter: {
        Page: 1,
        PerPage: 20,
        LocaleId: undefined,
        owner: undefined
      },
      showId: false,
      reportType: 1
    };
  },
  computed: {
    ...mapState(["clientHeight", "customerTree"]),
    ...mapState({
      orgProps: state =>
        Object.assign({}, state.props, { label: "Org", checkStrictly: true })
    })
  },
  filters: {},
  created() {
    this.getData();
  },
  methods: {
    sousuo() {
      this.filter.Page = 1;
      this.getData();
    },
    // date 代表指定的日期，格式：2018-09-27
    // day 传-1表始前一天，传1表始后一天
    // JS获取指定日期的前一天，后一天
    getNextDate(date, day) {
      var dd = new Date(date);
      dd.setDate(dd.getDate() + 1);
      var y = dd.getFullYear();
      var m =
        dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
      return y + "-" + m + "-" + d;
    },
    getData: function() {
      this.loading = true;
      var para = {
        Page: this.filter.Page,
        PerPage: this.filter.PerPage
      };
      if (this.reportType === 1) {
        if (!this.filter.AcquitBeginDate) {
          const now = new Date();
          const yy = now.getFullYear();
          const mm =
            now.getMonth() < 9
              ? "0" + (now.getMonth() + 1)
              : now.getMonth() + 1;
          const dd = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
          //   this.filter.AcquitBeginDate = `${yy}-${mm}-${dd}`;
          para.AcquitBeginDate = `${yy}-${mm}-${dd}`;
          para.AcquitEndDate = this.getNextDate(`${yy}-${mm}-${dd}`);
        } else {
          para.AcquitBeginDate = this.filter.AcquitBeginDate;
          para.AcquitEndDate = this.getNextDate(this.filter.AcquitBeginDate);
        }
      }
      if (this.reportType === 2) {
        para.AcquitMonth = this.filter.AcquitMonth;
      }
      if (this.reportType === 3) {
        para.AcquitYear = this.filter.AcquitYear;
      }
      if (this.reportType === 4) {
        para["AcquitBeginDate"] = this.filter.peorid[0];
        para["AcquitEndDate"] = this.filter.peorid[1];
      }
      if (this.filter.LocaleId) {
        para["LocaleId"] = this.filter.LocaleId;
      }
      if (this.filter.owner && this.filter.owner.length !== 0) {
        para["Owner"] = join(this.filter.owner);
      }
      //   para["LocaleWithDevice"] = !this.filter.LocaleWithDevice;
      this.$postNew("admin/queryLocaleData10MinTimes", para).then(res => {
        this.datalist = res.Data;
        this.total = res.resultsPageInfo.Total;
        this.loading = false;
      });
    },
    handleDownload() {
      var para = {
        Page: this.filter.Page,
        PerPage: this.filter.PerPage
      };
      if (this.reportType === 1) {
        if (!this.filter.AcquitBeginDate) {
          const now = new Date();
          const yy = now.getFullYear();
          const mm =
            now.getMonth() < 9
              ? "0" + (now.getMonth() + 1)
              : now.getMonth() + 1;
          const dd = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
          //   this.filter.AcquitBeginDate = `${yy}-${mm}-${dd}`;
          para.AcquitBeginDate = `${yy}-${mm}-${dd}`;
          para.AcquitEndDate = this.getNextDate(`${yy}-${mm}-${dd}`);
        } else {
          para.AcquitBeginDate = this.filter.AcquitBeginDate;
          para.AcquitEndDate = this.getNextDate(this.filter.AcquitBeginDate);
        }
      }
      if (this.reportType === 2) {
        para.AcquitMonth = this.filter.AcquitMonth;
      }
      if (this.reportType === 3) {
        para.AcquitYear = this.filter.AcquitYear;
      }
      if (this.reportType === 4) {
        para["AcquitBeginDate"] = this.filter.peorid[0];
        para["AcquitEndDate"] = this.filter.peorid[1];
      }
      if (this.filter.LocaleId) {
        para["LocaleId"] = this.filter.LocaleId;
      }
      if (this.filter.owner && this.filter.owner.length !== 0) {
        para["Owner"] = join(this.filter.owner);
      }
      para["LocaleWithDevice"] = !this.filter.LocaleWithDevice;
      this.$postNew("admin/queryLocaleData10MinTimes", para).then(res => {
        const dataArr = res.Data.map(v => {
          const newV = {
            监测点名称: v.LocaleName,
            监测点编号: v.LocaleId,
            超标次数: v.ExceedStandardTimes,
            油烟平均折算浓度: v.AvgEmissionsConc,
            颗粒物平均折算浓度: v.AvgGranuleConc,
            非甲烷总烃平均折算浓度: v.AvgHydrocarbonConc
          };
          return newV;
        });
        export_json("监测点超标统计", dataArr, {
          监测点名称: "监测点名称",
          监测点编号: "监测点编号",
          超标次数: "超标次数",
          油烟平均折算浓度: "油烟平均折算浓度",
          颗粒物平均折算浓度: "颗粒物平均折算浓度",
          非甲烷总烃平均折算浓度: "非甲烷总烃平均折算浓度"
        });
      });
    },
    handleRefresh() {
      this.filter.page = 1;
      this.filter.LocaleId = null;
      this.filter.owner = null;
      this.getData();
    },
    handlePageChange(val, field) {
      this.filter[field] = val;
      this.getData();
    }
  }
};
</script>

<style lang="scss" scoped>
.fa-exclamation-triangle {
  color: #ebb460;
}
.fa-bell {
  color: #f56c6c;
}
.el-icon-s-data {
  color: #8896b3;
}
.el-icon-orange {
  color: #409eff;
}
td .input-box input {
  border: none;
  width: 100%;
  height: 100%;
}
</style>
